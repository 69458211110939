/* eslint-disable camelcase */
import { useEffect, useState } from "react";

import { DatePickerSingle } from "@braze/beacon-lab-datepicker";
import { Select } from "@braze/beacon-lab-select";
import { StatusText } from "@braze/beacon-core";

import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  RequestForm,
  StyledBodyText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";

import { requiredLabel } from "src/components/required";
import { AccountResult, AppGroupResult } from "src/hooks/types";
import {
  EbrMergeOptions,
  EbrSlideBotRequest,
  useGenerateSlidebot,
} from "src/hooks/useGenerateSlidebot";
import { SelectOptionsType } from "./types";
import { getUserInformation } from "src/utils";
import { ThankYou } from "src/pages/thank-you";
import {
  EbrAttributionWindow,
  EbrExecutiveSummary,
  EbrMetrics,
  EbrReportType,
  EbrSlidebotTemplates,
  EbrValueDrivers,
} from "./constants";
import { AccountSearchSelect } from "src/components/AccountSearchSelect";
import { AppGroupSearchSelect } from "src/components/AppGroupSearchSelect";
import { SelectedInputStyle } from "./styles";

export const SlidebotFormEbr = () => {
  const [ebrSlidebot, ebrSlidebotResponse] =
    useGenerateSlidebot<EbrSlideBotRequest>("generate");
  const userInfo = getUserInformation();
  const now = new Date();
  const [selectedAccount, setSelectedAccount] = useState<AccountResult>();
  const [appGroup, setAppGroup] = useState<string[][]>([]);
  const [reportType, setReportType] = useState<string>(EbrReportType[0].value);
  const [attributionWindow, setAttributionWindow] = useState<string>(
    EbrAttributionWindow[0].value
  );

  const [executiveSummaries, setExecutiveSummaries] = useState<string[]>([]);

  const [endDate, setEndDate] = useState<Date>(now);

  const [valueDrivers, setValueDrivers] = useState<string[]>([]);

  const [metrics, setMetrics] = useState<string[][]>([[]]);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const [valueDriverOptions, setValueDriverOptions] = useState<
    SelectOptionsType[]
  >([]);

  const arrToOptions = (arrList: string[]): SelectOptionsType[] =>
    arrList.map((arr) => ({ label: arr, value: arr }));

  const optionsToArr = (optionsList: string[]): string[] =>
    optionsList.map((opt) => opt);

  const multiOptionsToArr = (optionsList: string[][] = [[]]): string[][] =>
    optionsList.map((opt) => {
      if (opt) {
        return opt.map((op) => op);
      } else {
        return [];
      }
    });

  const arrToMergeConfig = (
    prefix: string,
    type: string,
    arrList: string[],
    max: number = 3
  ) => {
    const mergeConfig: EbrMergeOptions = {};
    for (let i = 0; i < max; i++) {
      mergeConfig[`${prefix}_${type}_${i + 1}` as keyof EbrMergeOptions] =
        arrList[i] || "";
    }
    return mergeConfig;
  };

  useEffect(() => {
    const requiredFieldsFilled =
      !!endDate &&
      !!selectedAccount &&
      !!appGroup.length &&
      !!executiveSummaries.length &&
      !!valueDrivers.length;
    setDisableSubmit(!requiredFieldsFilled);
  }, [selectedAccount, appGroup, valueDrivers]);

  useEffect(() => {
    setValueDriverOptions(arrToOptions(EbrValueDrivers));
  }, []);

  const onSelectAccount = (account?: AccountResult) => {
    setSelectedAccount(account);
    setAppGroup([]);
  };

  const onAppGroupSelect = (appGroup: AppGroupResult[]) => {
    const appGroupNames = appGroup.map((app) => [
      app.APP_GROUP_NAME,
      app.APP_GROUP_ID,
    ]);
    setAppGroup(appGroupNames);
  };

  const handleValueDriver = (valueDriver: SelectOptionsType[]) => {
    setValueDrivers(
      valueDriver?.length ? valueDriver.map((vd) => vd.value) : []
    );
  };

  const handleExecutiveSummary = (executiveSummary: SelectOptionsType[]) => {
    setExecutiveSummaries(
      executiveSummary?.length ? executiveSummary.map((vd) => vd.value) : []
    );
  };

  const handleMetrics = (metric: SelectOptionsType[], index: number) => {
    const newMetrics = [...metrics];
    newMetrics[index] = metric ? metric.map((mt) => mt.value) : [];
    setMetrics(newMetrics);
  };

  const onSubmit = async () => {
    try {
      const appGroupInfo = appGroup?.[0] || ["", ""];
      const [
        Value_Driver_1 = "",
        Value_Driver_2 = "",
        Value_Driver_3 = "",
        Value_Driver_4 = "",
      ] = optionsToArr(valueDrivers);

      const [Executive_Summary_1 = "", Executive_Summary_2 = ""] =
        optionsToArr(executiveSummaries);


      let [metrics1 = [], metrics2 = [], metrics3 = [], metrics4 = []] =
        multiOptionsToArr(metrics);

      // Set the current and previous date based on the report type
      const monthOffset = reportType === "YoY" ? 12 : 3;
      const endDateFirstOfMonth = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        1
      );
      const previousDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - monthOffset,
        1
      );

      // Clear any metrics if the value driver was removed
      if (!Value_Driver_1) {
        metrics1 = [];
      }
      if (!Value_Driver_2) {
        metrics2 = [];
      }
      if (!Value_Driver_3) {
        metrics3 = [];
      }
      if (!Value_Driver_4) {
        metrics4 = [];
      }


      await ebrSlidebot({
        submitter_email: userInfo!.email!,
        submitter_name: userInfo!.name!,
        template_name: EbrSlidebotTemplates.EBR2,
        company_name: selectedAccount?.ACCOUNT_NAME,
        company_id: selectedAccount?.ACCOUNT_ID,
        file_name: `${selectedAccount?.ACCOUNT_NAME || ""} ${
          appGroupInfo?.[0] ? "- " + appGroupInfo?.[0] : ""
        } - `,
        config_option: {
          app_group_id: appGroupInfo[1],
          slack_notify_user_email: userInfo?.email,
          Report_Type: reportType,
          Attribution_Window: attributionWindow,
          end_date_1: [
            endDateFirstOfMonth.getFullYear(),
            ("0" + (endDateFirstOfMonth.getMonth() + 1)).slice(-2),
            ("0" + endDateFirstOfMonth.getDate()).slice(-2),
          ].join("-"),
          end_date_2: [
            previousDate.getFullYear(),
            ("0" + (previousDate.getMonth() + 1)).slice(-2),
            ("0" + previousDate.getDate()).slice(-2),
          ].join("-"),
        },
        merge_option: {
          Executive_Summary_1,
          Executive_Summary_2,
          Value_Driver_1,
          Value_Driver_2,
          Value_Driver_3,
          Value_Driver_4,
          ...arrToMergeConfig("Value_Driver_1", "Metric", metrics1),
          ...arrToMergeConfig("Value_Driver_2", "Metric", metrics2),
          ...arrToMergeConfig("Value_Driver_3", "Metric", metrics3),
          ...arrToMergeConfig("Value_Driver_4", "Metric", metrics4),
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "80px" }}>
        <FormPanel>
          {ebrSlidebotResponse.called && ebrSlidebotResponse.value ? (
            <>
              <ThankYou textVariation="Slidebot" />
            </>
          ) : (
            <FormPanelBody>
              <>
                <FormHeading level={2} style={{ margin: "12px 0 26px" }}>
                  Slide EBR2.0 Request
                </FormHeading>
                <FormDetails>
                  <SelectedInputStyle
                    label="Company"
                    htmlFor="slidebot-company"
                    formatLabel={requiredLabel}
                  >
                    <AccountSearchSelect onSelectAccount={onSelectAccount} />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="App Group"
                    htmlFor="slidebot-appgroup"
                    formatLabel={requiredLabel}
                  >
                    <AppGroupSearchSelect
                      onAppGroupSelect={onAppGroupSelect}
                      selectedAccount={selectedAccount}
                      isMulti={false}
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Executive Summary (max 2)"
                    htmlFor="slidebot-executive-summary"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      Executive Summary metrics appear on slide 5 of the
                      template, only the top two chosen will be populated onto
                      the slide
                    </StyledBodyText>
                    <Select
                      inputId="slidebot-executive-summary"
                      isClearable={false}
                      isDisabled={false}
                      isMulti
                      isSearchable
                      fluid
                      options={EbrExecutiveSummary}
                      onChange={handleExecutiveSummary}
                      placeholder="Select a Report Type"
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Time Comparison Period"
                    htmlFor="slidebot-report-type"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      Comparison Report Period provides two look backs. All data
                      generated will be provided from the previous period chosen
                      to the End Month selected. (i.e June 2024 + Year Over Year
                      generates June 2023 data, etc. )
                    </StyledBodyText>
                    <Select
                      inputId="slidebot-report-type"
                      isClearable={false}
                      isDisabled={false}
                      isSearchable
                      fluid
                      options={EbrReportType}
                      onChange={(e) => setReportType(e?.value ?? "")}
                      defaultValue={EbrReportType[0]}
                      placeholder="Select a Report Type"
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="End Month"
                    htmlFor="slidebot-time-frame"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      Data loads are conducted monthly at the end of the month.
                      The most current data will always be to the previous month.
                      While date select offers options down to the day, any date selected will select the month that date is in.
                    </StyledBodyText>
                    <DatePickerSingle
                      onChange={(e) => setEndDate(e ?? now)}
                      date={endDate}
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Attribution Window"
                    htmlFor="slidebot-attribution-window"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      Attribution Windows changes the look back window for
                      Purchase and Conversion events. This looks at when a
                      Purchase or Conversion occurred and looks when the last
                      user touchpoint was. Different windows provide different
                      look backs that determine what revenue should be
                      attributed to the event.{" "}
                    </StyledBodyText>
                    <Select
                      inputId="slidebot-attribution-window"
                      isClearable={false}
                      isDisabled={false}
                      isSearchable
                      fluid
                      options={EbrAttributionWindow}
                      onChange={(e) => setAttributionWindow(e?.value ?? "")}
                      defaultValue={EbrAttributionWindow[0]}
                      placeholder="Select an Attribution Window"
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Value Drivers (Upto 4)"
                    htmlFor="slidebot-value-drivers"
                    formatLabel={requiredLabel}
                  >
                    <Select
                      inputId="slidebot-value-drivers"
                      isClearable
                      isDisabled={false}
                      isMulti
                      isSearchable
                      fluid
                      options={valueDriverOptions}
                      onChange={handleValueDriver}
                      placeholder="Select the Value Drivers"
                    />
                  </SelectedInputStyle>
                  {valueDrivers &&
                    valueDrivers.map((driver, index) => (
                      <>
                        <SelectedInputStyle
                          label={`Metrics for: ${driver} (Max 3)`}
                          htmlFor={`slidebot-metrics-${index}`}
                        >
                          <StyledBodyText>
                            Only the top 3 metrics selected will populate into
                            the slides.
                          </StyledBodyText>
                          <Select
                            inputId={`slidebot-metrics-${index}`}
                            isClearable
                            isDisabled={false}
                            isMulti
                            isSearchable
                            fluid
                            options={arrToOptions(
                              EbrMetrics[driver as keyof typeof EbrMetrics]
                            )}
                            onChange={(e) => handleMetrics(e, index)}
                            placeholder={`Select relevant metrics for ${driver}`}
                          />
                        </SelectedInputStyle>
                      </>
                    ))}
                  <SubmitButton
                    onClick={onSubmit}
                    disabled={disableSubmit}
                    style={{ marginTop: "60px", width: "100%" }}
                  >
                    Submit Slidebot Request
                  </SubmitButton>
                  {ebrSlidebotResponse.called && ebrSlidebotResponse.error ? (
                    <StatusText displayIcon status="danger">
                      Failed to submit request. Please try again.
                    </StatusText>
                  ) : null}
                </FormDetails>
              </>
            </FormPanelBody>
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
